// Issues.js
import React from 'react';

const Issues = () => {
  return (
    <section>
      <h2>Key Issues</h2>
        <ul>
          <li>Tax Reform</li>
          <li>Federal Term Limits</li>
          <li>Immigration and Security</li>
        </ul>
    </section>
  );
};

export default Issues;
