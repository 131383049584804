// Header.js
import React from 'react';
import {Link} from 'react-router-dom'
import '../App.css'

const Header = () => {
  return (
    <header>
      <h1>Wesley Smith</h1>
        <img className="american-flag" src="/imgs/flag.png" alt="American Flag" />
      <nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/issues">Issues</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
