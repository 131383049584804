// Home.js
import React from 'react';

const Home = () => {
  return (
    <section id="home">
      <div className="hero">
        <h2>Welcome to the Official Website of The Wolf of Washington</h2>
        <p>Addressing the issues that matter to you!</p>
      </div>
      <div className="about" id="about">
        <h2>About {/* Name */} </h2>
               <p>
                Together we will fight useless taxes, hold elected officials 
                accountable, reform the upside down credit system, block big media,
                 big pharma, and big tech. We will attack the real government
                  spending issues by blocking spending legislation. We will
                   also fight for congressional term limits, which would
                    include 8 years in the House and 12 years in the Senate,
                     all while reforming our prison system and securing our
                      borders. America is always first!
               </p>
               <img src="/imgs/wsmith.jpeg" alt="profile" style={{ maxWidth: '100%', height: 'auto' }} />
      </div>
      <div className="issues" id="issues">
        <h2>Key Issues</h2>
        <ul>
          <li>Tax Reform</li>
          <li>Federal Term Limits</li>
          <li>Immigration and Security</li>
        </ul>
      </div>
      <div className="contact" id="contact">
        <h2>Contact {/* Name */}</h2>
        <p>Reach out to us for any questions or concerns.</p>
        {/* Add a contact form or contact information here */}
      </div>
    </section>
  );
};

export default Home;
