// About.js
import React from 'react';

const About = () => {
  return (
    <section>
      <h2>About Wesley</h2>
      <p>Wesley Smith was born and raised in 
            Arnold, MO. He is a proud patriot, Veteran, 
            American, and Constitutional Conservative. Wesley 
            isn’t a politician, nor will he ever be. He believes 
            representatives shouldn’t hide in Washington. 
            For too long members in congress have made promises
             they couldn’t keep and lied to their constituents, 
             only to take money and push agendas set by big businesses. 
             Wesley’s primary goal is to unite our community 
             by exposing corruption and holding elected officials 
             accountable. There will be no more double standards
              between elected officials and those that they govern.
               WE CAN’T SPELL COMMUNITY WITHOUT UNITY!</p>
    </section>
  );
};

export default About;
